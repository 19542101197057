<template>
	<button class="navbar-toggler"
			type="button"
			data-bs-toggle="collapse"
			data-bs-target="#navbarSupportedContent"
			aria-controls="navbarSupportedContent"
			aria-expanded="false"
			aria-label="Toggle navigation">
        <span class="navbar-toggler-icon d-flex justify-content-center align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z"
																									 fill="none"/><path
				d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/></svg>
        </span>
	</button>
	<div class="collapse navbar-collapse" id="navbarSupportedContent">
		<ul class="navbar-nav me-auto mb-2 mb-lg-0">
			<li class="nav-item">
				<Link class="nav-link d-flex justify-content-center align-items-center" href="/dashboard"
					  :class="{'active': linkIsActive('dashboard')}">
					<icon name="dashboard" class="d-lg-block mx-lg-auto p-2" width="50" height="50"/>
					<span>Dashboard</span>
				</Link>
			</li>
			<li class="nav-item" v-if="userCan('view leads')">
				<Link class="nav-link d-flex justify-content-center align-items-center" href="/leads"
					  :class="{'active': linkIsActive('leads')}">
					<icon name="insights" class="d-lg-block mx-lg-auto p-2" width="50" height="50"/>
					<span>MQLs</span>
				</Link>
			</li>
			<li class="nav-item" v-if="userCan('view leads')">
				<Link class="nav-link d-flex justify-content-center align-items-center" href="/checkouts"
					  :class="{'active': linkIsActive('leads')}">
					<icon name="insights" class="d-lg-block mx-lg-auto p-2" width="50" height="50"/>
					<span>Checkouts</span>
				</Link>
			</li>
			<li class="nav-item" v-if="userCan('view finder')">
				<Link class="nav-link d-flex justify-content-center align-items-center" href="/finder"
					  :class="{'active': linkIsActive('finder')}">
					<icon name="wizard" class="d-lg-block mx-lg-auto p-2" width="50" height="50"/>
					<span>Angebotsfinder</span>
				</Link>
			</li>
			<li class="nav-item" v-if="userCan('view reporting')">
				<Link class="nav-link d-flex justify-content-center align-items-center" href="/reportings"
					  :class="{'active': linkIsActive('reporting')}">
					<icon name="wizard" class="d-lg-block mx-lg-auto p-2" width="50" height="50"/>
					<span>Reportings</span>
				</Link>
			</li>
			<li class="nav-item" v-if="userCan('view phone-calls')">
				<Link class="nav-link d-flex justify-content-center align-items-center" href="/phone-calls"
					  :class="{'active': linkIsActive('phone-calls')}">
					<icon name="phoneCalls" class="d-lg-block mx-lg-auto p-2" width="50" height="50"/>
					<span>Anrufe</span>
				</Link>
			</li>
			<li class="nav-item dropdown admin-area-dropdown"
				v-if="userCan('edit users') || this.$parent.auth.user.roles.includes('Super-Admin')">
				<a class="nav-link dropdown-toggle d-flex justify-content-center align-items-center" href="#"
				   role="button" data-bs-toggle="dropdown" aria-expanded="false"
				   :class="{'active': linkIsActive(['unsent', 'blacklist', 'users'], true)}">
					<icon name="super-admin" class="d-lg-block m-0" width="50" height="50"/>
					<span>Admin-Bereich</span>
				</a>
				<ul class="dropdown-menu p-0 m-0">
					<li class="nav-item" v-if="userCan('edit users')">
						<Link class="nav-link d-flex align-items-center" href="/users"
							  :class="{'active': linkIsActive('users')}">
							<icon name="users" class="d-lg-block p-2" width="50" height="50"/>
							<span>Benutzer</span>
						</Link>
					</li>
					<li>
						<hr class="dropdown-divider m-0">
					</li>
					<li class="nav-item" v-if="this.$parent.auth.user.roles.includes('Super-Admin')">
						<Link class="nav-link d-flex align-items-center" href="/unsent" :class="{'active': linkIsActive('unsent')}">
							<icon name="leadsUnsent" class="d-lg-block p-2" width="50" height="50"/>
							<span>Unverschickte Leads</span>
						</Link>
					</li>
					<li>
						<hr class="dropdown-divider m-0">
					</li>
					<li class="nav-item" v-if="this.$parent.auth.user.roles.includes('Super-Admin')">
						<Link class="nav-link d-flex align-items-center" href="/blacklist"
							  :class="{'active': linkIsActive('blacklist')}">
							<icon name="leadsUnsent" class="d-lg-block p-2" width="50" height="50"/>
							<span>Blacklist</span>
						</Link>
					</li>
				</ul>
			</li>
		</ul>

		<div class="nav-item dropdown account-dropdown">
			<a class="nav-link dropdown-toggle d-flex justify-content-center align-items-center" href="#" role="button"
			   data-bs-toggle="dropdown" aria-expanded="false" :class="{'active': linkIsActive('myaccount')}">
				<icon name="account" class="d-lg-block mx-lg-auto p-2" width="50" height="50"/>
				<span>{{ $parent.auth.user.name }}</span>
			</a>
			<ul class="dropdown-menu dropdown-menu-lg-end p-0 m-0">
				<li>
					<Link class="dropdown-item" href="/myaccount" :class="{'active': linkIsActive('myaccount')}">
						<span>Benutzerprofil</span>
					</Link>
				</li>
				<li>
					<hr class="dropdown-divider m-0">
				</li>
				<li>
					<form @submit.prevent="logout">
						<Link as="button" method="delete" href="/logout" class="dropdown-item">
							Abmelden
						</Link>
					</form>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import {Link} from '@inertiajs/inertia-vue3';
import Icon from '@/Shared/Icon';

export default {
	components: {
		Icon,
		Link,
	},
	props: {
		//auth: Object,
	},
	mounted() {
		//console.log(this)
	},
	methods: {
		isUrl(...urls) {
			let currentUrl = this.$page.url.slice(1);
			if (urls[0] === '') {
				return currentUrl === '';
			}
			return urls.filter((url) => currentUrl.startsWith(url)).length;
		},
		linkIsActive(url, isArray = false) {
			const segments = this.$page.url.slice(1).split('?')[0].split('/');

			if(isArray)
			{
				return url.some((segment) => {
					return segments.includes(segment)
				})

			}
			return segments.includes(url);
		},
		userCan(permission) {
			if (this.$parent.auth.user.roles.includes('Super-Admin')) {
				return true;
			}

			return this.$parent.auth.user.permissions.filter((p) => p.name === permission).length > 0;
		}
	},
};
</script>
<style scoped lang="scss">
@import "resources/sass/partials/variables";

.navbar {
	padding: 0;
}

.navbar-collapse {
	@media screen and (min-width: 992px) {
		align-self: stretch;
	}

	.navbar-nav {
		@media screen and (min-width: 992px) {
			height: 100%;
		}
	}
}

.nav-item {
	@media screen and (min-width: 992px) {
		display: flex;
		justify-content: center;
	}

}

.nav-link {
	color: currentColor;
	flex-basis: 100%;

	svg {
		fill: currentColor;
	}

	&.active {
		background: $primary;
		color: #FFFFFF;
	}
}

.account-dropdown,
.admin-area-dropdown {

	@media screen and (min-width: 992px) {
		height: 100%;
	}

	.dropdown-menu {
		@media screen and (max-width: 991px) {
			position: relative;
		}
	}
}


</style>
