<script>
import BackendLayout from '@/Layouts/BackendLayout.vue';
import { Head, Link } from '@inertiajs/inertia-vue3';
import pickBy from 'lodash/pickBy';
import throttle from 'lodash/throttle';
import Layout from '@/Shared/Layout';
import {formatDate} from "@/custom";
import PieChart from '@/Shared/PieChart';
import BarChart from '@/Shared/BarChart';

export default {
    components: {
        BackendLayout,
        Link,
        Head,
        PieChart,
        BarChart,
    },
    layout: Layout,
    props: {
        checkouts: Object,
        limit: Number,
        filters: Object,
        offerers: Object,
        platforms: Object,
		leadStates: Object,
		total: Number,
        stats: Object
    },
    data()
    {
        return {
            options: {
                limit: this.limit,
                dateStart: this.filters?.dateStart,
                dateEnd: this.filters?.dateEnd,
                offerers: this.filters?.offerers,
                leadStates: this.filters?.leadStates,
                platforms: this.filters?.platforms
            },
            limits: [
                {label: '10', limit: 10},
                {label: '25', limit: 25},
                {label: '100', limit: 100},
                {label: '250', limit: 250},
            ],
        };
    },
    watch: {
        options: {
            deep: true,
            handler: throttle(
                function(oldVal, newVal)
                {
					if(this.options.offerers.length == 0) this.options.offerers = [-1];
					if(this.options.platforms.length == 0) this.options.platforms = [-1];
					if(this.options.leadStates.length == 0) this.options.leadStates = [-1];
                    this.$inertia.get(route('insights.checkouts'), pickBy(this.options), {preserveState: true});
                },
                150,
            ),
        },
    },
    computed: {
        noDataAvailable()
        {
            return this.filters.offerers.includes(-1) ? 'You are not allowed to view this data' : 'Nothing found';
        },
        statsSorted()
        {
            const leadStatus = Object.values(this.stats.leadStatus);
            return {leadStatus: leadStatus};
        }
    },
    methods: {
        dump()
        {
            //console.log(this, this.$parent.auth);
        },
        getPaginationLabel(label)
        {
            const props = {
                label: label,
                isPrev: false,
                isNext: false,
            };
            if(label.toLowerCase().includes('previous'))
            {
                props.label = '';
                props.isPrev = true;
            }
            if(label.toLowerCase().includes('next'))
            {
                props.label = '';
                props.isNext = true;
            }
            return props;
        },
        formatDate(value){
            return formatDate(value);
        },
        setDates(dates)
        {
            if(dates && dates != -1)
            {
                this.options.dateStart = this.filters[dates].dateStart;
                this.options.dateEnd = this.filters[dates].dateEnd;
            }
            else
            {
                this.options.dateStart = -1;
                this.options.dateEnd = -1;
            }
        },
        exportLeads()
        {
            window.location.href = route('insights.lead.export');
        },
    },
};
</script>

<template>
    <Head title="Digitale Checkouts"/>
	<h1>Digitale Checkouts</h1>
	
    <div class="filters">
        <div class="row">
			<div class="col-12">
				<span>Filter nach Zeitraum:</span>
			</div>
			<div class="col-12 col-md-6">
				<div class="row">
					<div class="col-auto">
						<label for="date-start" class="form-label">Von:</label>
						<input type="date" class="form-control" v-model="options.dateStart" id="date-start">
					</div>
					<div class="col-auto">
						<label for="date-end" class="form-label">Bis:</label>
						<input type="date" class="form-control" v-model="options.dateEnd" id="date-end" :min="options.dateStart">
					</div>
					<div class="col-auto">
						&nbsp;<br/>
						<button class="btn fs-6" @click="setDates(-1)">Datum zurücksetzen</button>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-6">
				<div class="row">
					<div class="col-12">
						<label for="quick-select-week">Schnellauswahl:</label>
					</div>
					<div class="col-auto">
						<button class="btn fs-6" id="quick-select-week" @click="setDates('lastWeek')">Letzte Woche</button>
					</div>
					<div class="col-auto">
						<button class="btn fs-6" @click="setDates('currentMonth')">Aktueller Monat</button>
					</div>
					<div class="col-auto">
						<button class="btn fs-6" @click="setDates('lastMonth')">Letzter Monat</button>
					</div>
				</div>
			</div>           
        </div>
    </div>
    <div class="row mb-3" v-if="offerers.length > 1">
        <div class="col-12">
            <span>Filter nach Zahlungsanbieter:</span>
        </div>
        <div class="col-auto" v-for="offerer in offerers">
            <div class="form-check">
				<input class="form-check-input" type="checkbox" :id="'offerer-' + offerer.id" name="offerers" :value="offerer.id" v-model="options.offerers"/>
                <label class="form-check-label" :for="'offerer-' + offerer.id">{{offerer.name}}</label>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-12">
            <span>Filter nach Status:</span>
        </div>
        <div class="col-auto" v-for="leadState in leadStates">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" :id="'leadState-' + leadState.id" name="leadStates" :value="leadState.id" v-model="options.leadStates"/>
                <label class="form-check-label" :for="'leadState-' + leadState.id">{{leadState.name}}</label>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-12">
            <span>Filter nach Werbeplattform:</span>
        </div>
        <div class="col-auto" v-for="platform in platforms">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" :id="'platform-' + platform.id" name="platforms" :value="platform.id" v-model="options.platforms"/>
                <label class="form-check-label" :for="'platform-' + platform.id">{{platform.name}}</label>
            </div>
        </div>
    </div>
    <div class="row mb-3 align-items-center">
        <div class="col-4">
			<div class="row">
				<div class="col-auto">
					<span class="text-sm">Anzahl der MQLs:&nbsp;{{total}}</span>
				</div>
			</div>
		</div>
		<div class="col-4"></div>
		<div class="col-4">
			<div class="row float-end">
				<div class="col-auto">
					<label class="form-label" for="limit">Einträge pro Seite:</label>
					<select id="limit" class="form-select" v-model="options.limit">
						<option :value="option.limit" v-for="option in limits">{{option.label}}</option>
					</select>
				</div>
			</div>
        </div>
    </div>
    <div class="table-responsive" v-if="checkouts.data.length > 0">
        <table class="table table-striped mb-3">
            <thead>
				<tr>
					<th>Initiale Übergabe</th>
					<th>Purchase</th>
					<th>Identifier</th>
					<th>Anbieter</th>
					<th>Angebot</th>
					<th>Status</th>
					<th>Vorname</th>
					<th>Nachname</th>
					<th>Firma</th>
					<th>Telefon</th>
					<th>E-Mail</th>
					<th>Quelle</th>
					<th>Werbeplattform</th>
				</tr>
            </thead>
            <tbody>
				<tr v-for="checkout in checkouts.data">
					<td><span :title="'ID: '+checkout.id">{{ formatDate(checkout.created_at) }}</span></td>
					<td>{{ formatDate(checkout.return_at) }}</td>
					<td>{{checkout.identifier}}</td>
					<td>{{checkout.offerer_name}}</td>
					<td>{{checkout.offer_name}}</td>
					<td>{{checkout.status_name}}</td>
					<td>{{checkout.firstname}}</td>
					<td>{{checkout.lastname}}</td>
					<td>{{checkout.company}}</td>
					<td>{{checkout.phone}}</td>
					<td>{{checkout.email}}</td>
					<td>{{checkout.source_name}}</td>
					<td>{{checkout.platform}}</td>
				</tr>
            </tbody>
        </table>
    </div>

    <h3 v-else>{{noDataAvailable}}</h3>
    <nav v-if="checkouts.links.length > 3">
        <ul class="pagination flex-wrap">
            <template v-for="link in checkouts.links">
                <li class="page-item" :class="{'disabled': link.url === null, 'active': link.active, 'prev': getPaginationLabel(link.label).isPrev, 'next': getPaginationLabel(link.label).isNext}">
                    <Link
                        class="page-link"
                        :class="{'prev': getPaginationLabel(link.label).isPrev, 'next': getPaginationLabel(link.label).isNext}"
                        :href="link.url"
                        v-html="getPaginationLabel(link.label).label"
                        v-if="link.url !== null">
                    </Link>
                    <span
                        class="page-link"
                        :class="{'prev': getPaginationLabel(link.label).isPrev, 'next': getPaginationLabel(link.label).isNext}"
                        v-else
                        v-html="getPaginationLabel(link.label).label">
                            </span>
                </li>
            </template>
        </ul>
    </nav>
	<br/>
	<br/>
	<h2>Auswertungen</h2>
	<br/>
    <div class="row justify-content-center">
        <div class="col-4">
			<p><strong>Status</strong></p>
            <pie-chart :items="statsSorted.leadStatus"></pie-chart>
        </div>
		<div class="col-8">
			<p><strong>Leads </strong></p>
            <bar-chart :items="statsSorted.leadStatus"></bar-chart>
        </div>
    </div>
</template>

<style scoped lang="scss">
@import "resources/sass/partials/variables";



</style>
