<script>
import BackendLayout from '@/Layouts/BackendLayout.vue';
import { Head, Link } from '@inertiajs/inertia-vue3';
import pickBy from 'lodash/pickBy';
import throttle from 'lodash/throttle';
import Layout from '@/Shared/Layout';
import { useForm } from '@inertiajs/inertia-vue3'

export default {
    components: {
        BackendLayout,
        Link,
        Head
    },
    layout: Layout,
    props: {
        offerers: Object,
    },
    data()
    {
		return {
			form: useForm({
				offerer: null,
				reportfile: null,
				notice: null
			})
		};
    },
    methods: {
		submit() {
			this.form.post(route('insights.reportings.import.store'), {
				onSuccess: () => {
				},
				onError: errors => {
				},
			});
		},
    },
};
</script>

<template>
    <Head title="Import eines CSV-Reportings"/>
	<h1>Import eines CSV-Reportings</h1>
	<br/>
	<br/>

	<form @submit.prevent="submit">
		<div class="row mb-3" v-if="offerers.length > 1">
			<div class="col-12">
				<div class="campaign-head">
					<label for="offerer">Anbieter:</label>
					<select id="offerer" class="form-select" v-model="form.offerer">
						<option :value="offerer.id" v-for="offerer in offerers">{{offerer.name}}</option>
					</select>
				</div>
			</div>
		</div>
		<div class="row mb-3">
			<div class="col-12">
				<span>CSV-Datei:</span>
			</div>
			<div class="col-auto">
				<input type="file" accept=".csv, text/csv" @input="form.reportfile = $event.target.files[0]" /><br/>
			</div>
		</div>
		<div class="row mb-3">
			<div class="col-12">
				<span>Anmerkungen (optional):</span>
			</div>
			<div class="col-auto">
				<textarea v-model="form.notice"></textarea>
			</div>
		</div>
		<div class="row mb-3">
			<div class="col-auto">
				<button type="submit">Datei importieren</button>
			</div>
		</div>
		<div>
			<progress v-if="form.progress" :value="form.progress.percentage" max="100">
				{{ form.progress.percentage }}%
			</progress>
		</div>
		
		<br>
		<a :href="route('insights.reportings')" target="_blank">Zurück zur Übersicht</a>
	</form>
</template>

<style scoped lang="scss">
@import "resources/sass/partials/variables";
</style>
