<script>
import BackendLayout from '@/Layouts/BackendLayout.vue';
import { Head, Link } from '@inertiajs/inertia-vue3';
import pickBy from 'lodash/pickBy';
import throttle from 'lodash/throttle';
import Layout from '@/Shared/Layout';
import {formatDate} from "@/custom";
import PieChart from '@/Shared/PieChart';
import BarChart from '@/Shared/BarChart';
import { useForm } from '@inertiajs/inertia-vue3'

export default {
    components: {
        BackendLayout,
        Link,
        Head,
        PieChart,
        BarChart,
    },
    layout: Layout,
    props: {
        reportingEntries: Object,
        limit: Number,
        filters: Object,
        offerers: Object,
		total: Number
    },
    data()
    {
        return {
			form: useForm({
				name: null,
				avatar: null,
			  }),
            options: {
                limit: this.limit,
                dateStart: this.filters?.dateStart,
                dateEnd: this.filters?.dateEnd,
                offerers: this.filters?.offerers,
                campaignId: this.filters?.campaignId,
                leadStates: this.filters?.leadStates
            },
            limits: [
                {label: '10', limit: 10},
                {label: '25', limit: 25},
                {label: '100', limit: 100},
                {label: '250', limit: 250},
            ],
        };
    },
    watch: {
        options: {
            deep: true,
            handler: throttle(
                function(oldVal, newVal)
                {
                    this.$inertia.get(route('insights.reportingEntries'), pickBy(this.options), {preserveState: true});
                },
                150,
            ),
        },
    },
    computed: {
        noDataAvailable()
        {
            return this.filters.offerers.includes(-1) ? 'You are not allowed to view this data' : 'Nothing found';
        }
    },
    methods: {
		submit() {
			this.form.post('/reportings/import')
		},
        dump()
        {
            //console.log(this, this.$parent.auth);
        },
        getPaginationLabel(label)
        {
            const props = {
                label: label,
                isPrev: false,
                isNext: false,
            };
            if(label.toLowerCase().includes('previous'))
            {
                props.label = '';
                props.isPrev = true;
            }
            if(label.toLowerCase().includes('next'))
            {
                props.label = '';
                props.isNext = true;
            }
            return props;
        },
        formatDate(value){
            return formatDate(value);
        },
        setDates(dates)
        {
            if(dates && dates != -1)
            {
                this.options.dateStart = this.filters[dates].dateStart;
                this.options.dateEnd = this.filters[dates].dateEnd;
            }
            else
            {
                this.options.dateStart = -1;
                this.options.dateEnd = -1;
            }
        },
        exportLeads()
        {
            //window.location.href = route('insights.lead.export');
        },
    },
};
</script>

<template>
    <Head title="Report importieren"/>
	<h1>Report importieren</h1>

	Success:<br/>
	{{ session('success') }}<br/>
	<br/>
	Mesage:<br/>
	{{ session('message') }}
	
	<br>
	<a :href="route('insights.reportings')" target="_blank">Zurück zur Übersicht</a>
</template>

<style scoped lang="scss">
@import "resources/sass/partials/variables";
</style>
